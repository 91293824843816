import React, { useState, useMemo } from 'react';
import { useNavigate } from "react-router-dom";

// react bootstrap
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

// this is a component that will display the bets in a table
//table sortable by ev

// exclude bookmakers marked as false in session storage

export default function BetsTable() {
    const navigate = useNavigate();
    const [bets, setBets] = useState(JSON.parse(sessionStorage.getItem('bets')));
    const [bookmakers, setBookmakers] = useState(JSON.parse(localStorage.getItem('bookmakers')));

    return (
        <div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Team</th>
                        <th>Bookmaker</th>
                        <th>EV</th>
                        <th>Odds</th>
                    </tr>
                </thead>
                <tbody>
                    {bets.map(bet => (
                        <tr key={bet.ev}>
                            <td>{bet.team}</td>
                            <td>{bet.bookmaker}</td>
                            <td>{bet.ev}</td>
                            <td>{bet.odds}</td>

                        </tr>
                    ))}
                </tbody>
            </Table>
            <Button onClick={() => navigate("/settings")}>Go to Settings to Enable Books</Button>
        </div>
    );
}